import React, { Component } from 'react'
import VideoCover from 'react-video-cover'

class backgroundVideo extends Component {
  render() {
    const videoOptions = {
      src: this.props.source,
      autoplay: 'autoplay',
      mute: 'mute',
      muted: 'true',
      loop: 'loop',
    }
    return <VideoCover muted videoOptions={videoOptions} />
  }
}

export default backgroundVideo
