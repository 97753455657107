import React, { Component } from 'react'
import Slider from 'react-slick'
import '../styles/react-slick-css.jsx'
import tw from '../../tailwind.js'
import { css } from 'emotion'
import { injectGlobal } from 'emotion'
import 'typeface-major-mono-display'

injectGlobal`
.quoteSlider {
  .slick-dots {
    bottom: -15px;
    z-index: 3;
  }
  .slick-dots li button::before {
    font-size: 18px;
  }
  .slick-dots li button::before {
    color: white;
    content: '○';
  }
  .slick-dots li.slick-active button::before {
    color: white;
    content: '●';

  }
  .slick-arrow {
    height: 80px;
    width: 80px;
    z-index: 2;
  }
  .slick-prev {
    left: -80px;
  }
  .slick-next {
    right: -80px;
  }
  .slick-prev::before {
    content: "<";
  }
  .slick-next::before {
    content: ">";
  }
  .slick-prev::before, .slick-next::before {
    border-radius: 50%;
    color: white;
    font-size: 80px;
    font-family: Major Mono Display !important;
    width: 80px;
    display: block;
  }
}
`

const styles = {
  quote: css(tw`text-white text-center`),
  quoteTitle: css(tw`uppercase text-5xl mb-0`),
  quoteAttribution: css(tw` font-normal text-2xl`),
}

class quoteSlider extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerMode: false,
      centerPadding: '30px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div className="quoteSlider">
        <Slider {...settings}>
          {this.props.quotes.map(quote => (
            <div key={quote.id} className={styles.quote}>
              <h2 className={styles.quoteTitle}>{quote.quote}</h2>
              <p className={styles.quoteAttribution}>{quote.attribution}</p>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

export default quoteSlider
