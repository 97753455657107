import React, { Component } from 'react'
import Image from 'gatsby-image'
import Slider from 'react-slick'
import '../styles/react-slick-css.jsx'
// import tw from '../../tailwind.js'
import { css } from 'emotion'
import { injectGlobal } from 'emotion'
import 'typeface-major-mono-display'

injectGlobal`
.logoSlider {
  .slick-dots {
    bottom: -15px;
    z-index: 3;
  }
  .slick-dots li button::before {
    font-size: 18px;
  }
  .slick-dots li button::before {
    color: #C6D27E;
    content: '○';
  }
  .slick-dots li.slick-active button::before {
    color: #C6D27E;
    content: '●';

  }
  .slick-arrow {
    height: 80px;
    width: 80px;
    z-index: 2;
  }
  .slick-prev {
    left: -80px;
  }
  .slick-next {
    right: -80px;
  }
  .slick-prev::before {
    content: "<";
  }
  .slick-next::before {
    content: ">";
  }
  .slick-prev::before, .slick-next::before {
    border-radius: 50%;
    color: #9B9B9B;
    font-size: 80px;
    font-family: Major Mono Display !important;
    width: 80px;
    display: block;
  }
}
`

const styles = {
  logo: css(`
    max-height: 100px !important;
    display: flex !important;
    flex-direction: column !important;
    .gatsby-image-wrapper {
      max-height: 100px;
      width: 100% !important;
      img {
        object-fit: contain !important;
      }
    }
  `),
  logoContainer: css(`
    box-sizing: border-box;
    padding: 2rem;
  `),
}

class logoSlider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      centerMode: true,
      centerPadding: '30px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    }
    return (
      <div className="logoSlider">
        <Slider {...settings}>
          {this.props.logos.map(client => (
            <div key={client.id} className={styles.logoContainer}>
              <div className={styles.logo}>
                <Image fixed={client.logo.fixed} />
              </div>
            </div>
          ))}
          {this.props.logos.map(client => (
            <div key={client.id + '-1'} className={styles.logoContainer}>
              <div className={styles.logo}>
                <Image fixed={client.logo.fixed} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

export default logoSlider
