import React from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'
import BackgroundVideo from '../components/backgroundVideo'

import { injectGlobal } from 'emotion'
import Image from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import LogoSlider from '../components/logoSlider.jsx'
import QuoteSlider from '../components/quoteSlider.jsx'
import Footer from '../components/footer.jsx'

import BarbershopFont from '../fonts/barbershop-in-thailand.ttf'
import { styles } from '../styles/styles.jsx'

injectGlobal`
@font-face {
  font-family: Barbershop in Thailand;
  src: url(${BarbershopFont});
}
`

const IndexPage = ({ data }) => (
  <div>
    <BackgroundImage
      className={styles.header}
      fluid={data.datoCmsHome.headerImage.fluid}
    >
      <div className={styles.videoWrapper}>
        <BackgroundVideo source={'/monx-set-up-footage2.mp4'} />
      </div>
      <div className={styles.backgroundColor} />
      <div className={styles.headerContainer}>
        <div className={styles.menu} style={{ boxSizing: 'border-box' }}>
          <span className={styles.navButton}>
            <a href="#about" className={styles.link}>
              About
            </a>
          </span>
          <span className={styles.navButton}>
            <Link to={'/portfolio'} className={styles.link}>
              Portfolio
            </Link>
          </span>
          <span className={styles.navButton}>
            <Link to={'/instablog'} className={styles.link}>
              Instablog
            </Link>
          </span>
          {/* <span className={styles.navButton}>
            <Link to={'/blog'} className={styles.link}>
              Blog
            </Link>
          </span> */}
          <span className={styles.navButton}>
            <Link to={'/contact'} className={styles.link}>
              Contact
            </Link>
          </span>
        </div>
        <div className={styles.brand + ' ' + styles.brandPadding}>
          <Image fluid={data.datoCmsHome.headerLogo.fluid} />
        </div>
        <div className={styles.divider} />
        <div className={styles.quoteSliderContainer}>
          <QuoteSlider quotes={data.datoCmsHome.quotes} />
          {/* {data.datoCmsHome.quotes.map(quote => ( */}
          {/*   <div className={styles.quote}> */}
          {/*     <h2 className={styles.quoteTitle}>{quote.quote}</h2> */}
          {/*     <p>{quote.attribution}</p> */}
          {/*   </div> */}
          {/* ))} */}
        </div>
      </div>
    </BackgroundImage>
    <div className={styles.team} id="about">
      <div className={styles.teamContainerOuter}>
        <BackgroundImage
          className={styles.teamContainerBg}
          style={{ boxSizing: 'border-box' }}
          fluid={data.datoCmsHome.teamBackgroundImage.fluid}
        >
          <div className={styles.teamContainer}>
            <div
              style={{ boxSizing: 'border-box' }}
              className={styles.teamContent}
            >
              <h1 id="#about" className={styles.teamTitle}>
                {data.datoCmsHome.teamTitle}
              </h1>
              <p className={styles.teamDescription}>
                {data.datoCmsHome.teamDescription}
              </p>

              {data.datoCmsHome.teamMembers.map((team, i) => (
                <div
                  className={
                    i % 2 ? styles.teamMemberReverse : styles.teamMember
                  }
                  style={{color: "#464E47"}}
                >
                  <div className={styles.teamMemberImage}>
                    <Image fluid={team.portrait.fluid} style={{height: "100%"}} />
                  </div>
                  <div
                    style={{ boxSizing: 'border-box' }}
                    className={
                      styles.teamMemberContent +
                      (i % 2
                        ? ' ' + styles.teamMemberBg
                        : ' ' + styles.teamMemberBgReverse)
                    }
                  >
                    <h2 className={styles.teamMemberName} style={{color: "white"}}>{team.name}</h2>
                    <p className={styles.teamMemberTitle} style={{color: "#464E47"}}>{team.title}</p>
                    <div className={styles.teamMemberDescriptionFont} style={{color: "#454E47"}}  dangerouslySetInnerHTML={{ __html: team.description }}></div>
                  </div>
                  {/* <div className={styles.teamMemberDescription}>
                    <p className={styles.teamMemberDescriptionFont}>{team.description}</p>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>
    <BackgroundImage
      className=""
      fluid={data.datoCmsHome.missionBackgroundImage.fluid}
    >
      <div
        className={
          styles.missionBgOverlay + ' ' + styles.missionBgOverlayDisplay
        }
        id="work"
      />
      <div className={styles.container}>
        <div className={styles.missionContainer}>
          <div className={styles.missionContent}>
            <h1 className={styles.missionTitle}>
              {data.datoCmsHome.missionTitle}
            </h1>
            <p className={styles.missionText}>{data.datoCmsHome.missionText}</p>
          </div>
        </div>
      </div>
    </BackgroundImage>
    <div
      className={styles.workContainerOuter}
      style={{ backgroundColor: '#F6F6F6', overflow: 'auto' }}
    >
      <div className={styles.container}>
        <h1 className={styles.workTitle} id="#work">
          Work We Did
        </h1>
        <div className={styles.workContainer}>
          {data.datoCmsHome.featuredWork.map(item => (
            <div
              className={styles.workItemContainer}
              key={item.id}
              style={{ boxSizing: 'border-box' }}
            >
              <div className={styles.workItem}>
              <BackgroundImage 
                fluid={item.image.fluid}
                className={styles.workImage + ' ' + styles.workImageBg}
              />
              <div className={styles.workContent}>
                <p className={styles.workTag}>Case Study</p>
                <h2 className={styles.workItemTitle}>{item.title}</h2>
                <p className={styles.workDescription}>{item.description}</p>
                <Link
                  className={styles.workLink}
                  to={'/portfolio/' + item.slug}
                >
                  View More
                </Link>
              </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <span
            className={styles.portfolioLink}
            style={{ borderWidth: '1px', cursor: 'pointer' }}
            onClick={() => navigate('/portfolio')}
          >
            See All Case Studies
          </span>
        </div>
      </div>
    </div>
    <div className={styles.logoContainer}>
      <div className={styles.container}>
        <h1 className={styles.logoTitle}>Our Clients</h1>
        <LogoSlider logos={data.datoCmsHome.featuredClients} />
      </div>
    </div>
    <Footer />
  </div>
)

export default IndexPage

export const query = graphql`
  query homePageQuery {
    datoCmsHome {
      headerLogo {
        fluid(maxWidth: 500, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      headerImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      quotes {
        id
        quote
        attribution
      }
      teamTitle
      teamDescription
      teamMembers {
        name
        title
        description
        portrait {
          fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      teamBackgroundImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      missionTitle
      missionText
      missionBackgroundImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredWork {
        title
        description
        image {
          fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        author {
          name
          title
        }
        slug
      }
      featuredClients {
        name
        logo {
          fixed(height: 100, imgixParams: { fm: "png", auto: "compress" }) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
    }
  }
`
